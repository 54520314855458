import React, { Component } from 'react'
import Carousel from './ControlledCarousel';
import Mainbody from './Mainbody';
import CalltoAction from './cta';
import backingImage from'./assets/nijatech_slide_backbg.jpeg';


const styles = {
  webdisplay: {
    padding:0, margin:0, display: 'flex',  justifyContent:'center',
    backgroundImage: `url(${backingImage})`,
    backgroundSize:'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  width: '100%'
  },
  mbiledisplay: {
    padding:0, margin:0, display: 'flex', justifyContent:'center',
    backgroundImage: `url(${backingImage})`,
    backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  width: '100%'
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#1560bd',
  },
};
interface HomeProps {
  // define any props that are passed to Home component, or leave empty if none
}

interface HomeState {
  isMobile: boolean;
}
export default class Home extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.state = {
      isMobile: window.innerWidth < 768,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  render() {
    const { isMobile } = this.state;

    return (
      <div style={isMobile ? styles.mbiledisplay : styles.webdisplay}>
        <div style={{flexDirection: 'column'}}>
            <CalltoAction />
            <Carousel />
            <Mainbody />
        </div>
      </div>
    );
  }
}
