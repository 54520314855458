import React, { Component } from 'react'

export default class Mainbody extends Component {
  render() {
    return (
        <div>
      <div className='container-fluid' style={{fontFamily:'Roboto', backgroundColor:'#fff'}}>
      <div className='row'>
            <div className='col-sm-12' style={{padding:20, textAlign:'center'}}>               
               <section id='OurAdvantage' style={{textAlign:'center', fontSize:28, fontWeight:700}}> 
               WHY US - OUR ADVANTAGE
               </section>
            </div>
        </div>
        <div className='row' style={{padding:5, borderBottomWidth:0, borderBottomStyle:'solid', borderBottomColor:'#ccc'}}>
            <div className='col-sm-12'>           
               <div style={{fontSize:20, padding:10,textAlign:'justify'}}>
                 Welcome to <span style={{fontWeight:700}}>Nijatech Nigeria Limited </span> – Your one-stop center for quality gadget! Why settle for ordinary when you can experience the extraordinary? <span style={{color:'blue', fontWeight:700}}> Nijatech </span> is not just a company; it's your tech haven, where authenticity meets excellence!
               Thinking of getting a new or used Apple or Samsung Gadget, the facts about us, listed below, will surely convince you to patronise us for your own peace of mind! 
               </div>
            </div>
        </div>
        <hr />
        <div className='row' style={{padding:5, borderBottomWidth:0, borderBottomStyle:'solid', borderBottomColor:'#ccc'}}>
            <div className='col-sm-8'> 
       
        <div className='row' style={{borderBottomWidth:0, marginTop:2, borderBottomStyle:'solid', borderBottomColor:'#ccc'}}>
            <div className='row'>
            <div className='col-sm-12'> 
            <h3 style={{fontWeight:900, textAlign:'center'}}>✨ Originality Guaranteed </h3>
            <hr style={{borderColor:'#ddd'}}/>
            </div>
              </div> 
           
            <div className='row'>
            <div className='col-sm-4'>
           <div style={{justifyContent:'center', backgroundColor:'#fff', padding:55}}>
            <img src= {require("./assets/achievement.png")} alt="Nijatech Origal gadgets" className='img-fluid'  style={{                     
                      maxWidth: '100%', // Ensure the image doesn't exceed its container's width
                      height: 'auto', // Maintain the image's aspect ratio
                    }}/>  </div> 
            </div>
            <div className='col-sm-8' style={{padding:20}}> 
              <div style={{padding:10, borderWidth:1, borderColor:'#ddd', justifyContent:'center'}}>    
             <div style={{textAlign:'justify', fontFamily:'Roboto', marginTop:5, padding:10,fontSize:20}}>              
At Nijatech, we take pride in being the major importer of smartphones and gadgets that redefine perfection. Every phone, whether brand new or Used is gently pre-loved, is meticulously sourced, authenticated, and offered to you in its original, pristine condition. Say goodbye to compromises – say hello to authenticity!
         <br /><br />  <p> <b>Originality is our Signature: We guarantee authenticity in every gadget</b></p>  
            </div>
              </div> 
            </div>
        </div>
        </div>
        <div className='row'>
            <div className='col-sm-12'> 
            <h3 style={{fontWeight:900, textAlign:'center'}}>🔧 Expert Repairs, Exceptional Care</h3>
            <hr style={{borderColor:'#ddd'}}/>
            </div>
              </div> 
        <div className='row'>  
        <div className='col-sm-4'>
            <div style={{justifyContent:'center', backgroundColor:'#fff', padding:30}}>
            <img src= {require("./assets/nijatech_phne_repair_service.jpeg")} alt="Nijatech Original gadgets" className='img-fluid' style={{width:'100%'}}/>  </div> 
            </div>  
        <div className='col-sm-8'> 
              <div style={{padding:10, borderWidth:1, borderColor:'#ddd', justifyContent:'center'}}>   
             <div style={{textAlign:'justify', fontFamily:'Roboto', padding:10,fontSize:18}}>              
Encountered a bump on the tech road? Fear not! Our squad of qualified engineers is here to bring your gadgets back to life. Nijatech doesn't just sell; we nurture and care for your devices. Trust us to handle your repairs with precision, ensuring your gadgets are in top-notch condition, just like the day you unboxed them.
             <p>Expert Repairs, Prompt Service: Our engineers are at your service, ensuring your devices stay flawless.</p> </div>
              </div> 
            </div>   
        </div>
        <div className='row'>
            <div className='col-sm-12'> 
            <h3 style={{fontWeight:900, textAlign:'center'}}> 💸 Sell or Swap with Ease</h3>
            <hr style={{borderColor:'#ddd'}}/>
            </div>
              </div> 
        <div className='row' style={{marginTop:5}}>  
        <div className='col-sm-4'>
        <div style={{justifyContent:'center', backgroundColor:'#ddd', padding:30}}>
            <img src= {require("./assets/smartphoneswap.jpg")} alt="Nijatech Origal gadgets" className='img-fluid' style={{width:'100%'}}/>  </div> 
            </div>         
            <div className='col-sm-8'> 
              <div style={{textAlign:'justify', fontFamily:'Roboto', padding:10, fontSize:18}}>             
Ready for an upgrade? At Nijatech, we understand that tech evolves, and so do your needs. That's why we offer you the freedom to sell or swap your current devices hassle-free. Your old gadgets not only find a new home but also contribute to a sustainable tech ecosystem.
              <p>Upgrade with Ease: Sell or swap your gadgets effortlessly, embracing the latest in tech.</p> </div>
              </div>                
            </div> 
            </div>
            <div className='col-sm-4'>             
            <div className='row' style={{borderBottomWidth:0, marginTop:2, borderBottomStyle:'solid', borderBottomColor:'#ccc'}}>
            <div className='col-sm-12'> 
              <h4 style={{fontWeight:900, textAlign:'center'}}>FREQUENTLY ASKED QUESTI0NS</h4> 
              <ul>
              <li style={{padding:10}}>
                  <h5>What type/brand of gadgets can I get at Nijatech </h5>
                  <div>
                At Nijatech, we specialize in the sales and repair of original Apple and Samsung gadgets
                  </div>
                </li>
                <li style={{padding:10}}>
                  <h5>How do I check if a phone is original?</h5>
                  <div>
                  IMEI Check (for Phones): Dial *#06# on your phone to get the IMEI number. Then, visit an IMEI checker like IMEI.info or the manufacturer's website to verify if the IMEI matches the official product specifications.
                  </div>
                </li>
                <li style={{padding:10}}>
                  <h5>Can I swap my device at Nijatech even if I bought from another store?</h5>
                  <div>
                  Yes! You can, as long as you possess all required documents.
                  </div>
                </li>
                <li style={{padding:10}}>
                  <h5>What documents do I need to provide for phone swap</h5>
                  <div>
                  You would need to provide the following documents: 
                  <ol><li>Purchase Receipt</li><li>Means of Identification</li> <li>A selfie with the Device</li><li>A Letter of indeminity</li></ol>
                  </div>
                </li>
                <li style={{padding:10}}>
                  <h5>What kinds of repairs can I do at Nijatech </h5>
                  <div>
                We posses the technical know-how to fix all the types of hardware and software issues on any smartphone and other gadgets
                  </div>
                </li>
                <li style={{padding:10}}>
                  <h5>What time is of the day is the office open? </h5>
                  <div>
                    We are open for business all days of the week except Sundays! 
                  </div>
                </li>
              </ul> 
            </div>      
            </div>  
            </div>  
            </div>  
            <div className='row'>
            <div className='col-sm-12' style={{padding:10, textAlign:'justify', fontSize:20}}>
            Your tech journey deserves the best, and Nijatech Nigeria Ltd is here to deliver nothing short of excellence. Step into a world where innovation meets trust, where gadgets tell a story of quality, and where every click is a seamless experience.
             Ready to elevate your tech game? Explore Nijatech today – Because Excellence Is Non-Negotiable! 
            </div>
            </div>  
        <div className='row' style={{fontFamily:'Roboto', marginTop:2, backgroundColor:'#041E42'}}>
                <div className='col-sm-7'>                
                <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%', justifyContent:'center'}}> 
                <h4 style={{fontWeight:400, color:'#fff', textAlign:'justify'}}><span style={{fontWeight:700}}>**</span>  BUY & SWAP SERVICE TERMS AND CONDITION </h4>
              <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
              <div style={{textAlign:'justify', color:'#fff'}}>
                Customer who wish to swap their existing phone or sell their phones to us should please read and understand the points outlined below:
                <ul>
                    <li style={{padding:5}}>Buy and Swap services are only available at our office in Computer Village, Ikeja </li>
                    <li style={{padding:5}}>You must provide proof of ownership of the phone </li>
                    <li style={{padding:5}}>Your phone must be in good working condition</li>
                    <li style={{padding:5}}>Take a picture with your phone in our office before the transaction - for our record</li>                   
                </ul>
                PLEASE NOTE:
                <h4 style={{fontWeight:700}}> WE DO NOT ACCEPT OR TRADE IN STOLEN PHONES!</h4>
              </div>
              </div>              
            </div>
            <div className='col-sm-5' style={{textAlign:'center'}}> 
              <div style={{padding:20, borderWidth:1, borderColor:'#ddd',width:'95%',  justifyContent:'center'}}> 
              <section id="Contactus">
              <h4 style={{fontWeight:400, color:'#fff'}}> CONTACT US </h4>
              <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>  
              <div>
                <div style={{color:'#fff', fontWeight:600}}> 
                <h5 style={{fontWeight:600,fontStyle:'bold'}}> Sales Hotline: </h5>
                <div style={{fontWeight:600,fontStyle:'bold',fontSize:22}}> Call: 0903 913 7405 | Chat: 0803 331 5354 </div>
                <h5 style={{fontWeight:600,fontStyle:'bold'}}>  </h5>
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>               
                <div style={{color:'#fff', fontWeight:600}}> 
                Email: info@nijatech.ng  |  sundayadewusi@nijatech.ng
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
                <div style={{color:'#fff', fontWeight:600}}>
                  <a className='btn btn-lg' style={{ textDecoration: 'none', color: 'inherit', cursor: 'default' }} href="instagram://user?username=NijatechGadgets" target="_blank" rel="noopener noreferrer" >
                  Follow Us: <img src= {require("./assets/nijatech_instagram_accunt.png")} alt="nijatech instagram" className='img-fluid' width="100" height="100"/> @NijatechGadgets 
                  </a>
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
                <div style={{color:'#fff', fontWeight:600}}> 
                    Office Address: 11, Otigba Street (3rd floor), opposite Fidelity Bank, Computer Village (Opposite Awolowo House), Ikeja, Lagos. 
                </div>
                <hr style={{borderColor:'#fff', borderStyle:'solid'}}/>
                <div style={{color:'#fff', fontWeight:600}}> 
                    For Complaints: +234 (0)802 654 4943
                </div>              
              </div>   
              </section>  
              </div>                
            </div>  
            </div>
            <div className='row' style={{fontFamily:'Roboto',padding:20, borderTopWidth:1, borderTopColor:'#fff', borderTopStyle:'solid', backgroundColor:'#041E42',}}>
            <div className='col-sm-12' style={{textAlign:'center'}}>
                <h6 style={{color:'#fff'}}> Nijatech Nigeria Limited &copy; 2024. All rights reserved</h6>
                </div>
            </div>
        </div> 
      
   
    </div>
    )
  }
}
