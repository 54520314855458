import React, { Component } from 'react'

export default class cta extends Component {
  render() {
    return (
        <div style={{fontFamily:'Roboto',fontWeight:600, color:'#fff', backgroundColor:'green', width:'100%', textAlign:'center', padding:10,top:0}}>
          Take advantage of our Deals-of-the-day promo Contact Sales on 09039137405! <br /> <a className='btn btn-warning btn-lg' href="tel:+2349039137405">CALL N0W!</a>
       </div>
    )
  }
}
